const calculateSpace = (gridHeight, length) => {
  const spaces = new Array(length).fill('');

  return spaces.map((_space, index) => {
    const newSpace = index * gridHeight;

    return newSpace;
  });
};

const spaces = calculateSpace(6, 241);

export default {
  colors: {
    text: '#333',
    background: '#fff',
    primary: '#639',
    secondary: '#ff6347',

    white: '#fff',
    mustard: '#F2B952',
    orange: '#EA7049',
    blue: '#3B4AB6',
    pig: '#EFB9B9',
    basementPig: '#FDF8F8',
  },
  fonts: {
    body: "'Work Sans', system-ui, sans-serif",
    heading: "'ArgestaHeadline', system-ui, sans-serif",
    monospace: 'Menlo, monospace',
  },
  fontWeights: {
    body: 400,
    regular: 400,
    medium: 500,
    semiBold: 600,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  sizes: spaces,
  space: spaces,
  text: {
    default: {
      padding: '0.05px 0',
      '::before': {
        content: "''",
        display: 'block',
        height: 0,
      },
      '::after': {
        content: "''",
        display: 'block',
        height: 0,
      },
      strong: {
        fontWeight: '700',
      },
    },
    h0: {
      fontFamily: 'heading',
      variant: 'text.default',
      marginBottom: [3, 6],
      fontSize: ['25.7143px', '51.4286px'],
      lineHeight: ['30px', '60px'],
      '::before': {
        marginTop: ['-0.2353em', '-0.2343em'],
      },
      '::after': {
        marginBottom: ['-0.2353em', '-0.2343em'],
      },
    },
    h1: {
      fontFamily: 'heading',
      variant: 'text.default',
      marginBottom: [3, 6],
      fontSize: ['25.7143px', '42.8571px'],
      lineHeight: ['30px', '54px'],
      '::before': {
        marginTop: ['-0.2353em', '-0.2812em'],
      },
      '::after': {
        marginBottom: ['-0.2353em', '-0.2812em'],
      },
    },
    h2: {
      fontFamily: 'heading',
      variant: 'text.default',
      fontSize: ['25.7143px', '34.2857px'],
      lineHeight: ['30px', '48px'],
      '::before': {
        marginTop: ['-0.2353em', '-0.3515em'],
      },
      '::after': {
        marginBottom: ['-0.2353em', '-0.3515em'],
      },
    },
    h3: {
      fontFamily: 'heading',
      variant: 'text.default',
      fontSize: '17.1429px',
      lineHeight: '24px',
      '::before': {
        marginTop: '-0.3529em',
      },
      '::after': {
        marginBottom: '-0.3529em',
      },
    },
    body: {
      fontFamily: 'body',
      variant: 'text.default',
      fontSize: ['12.1212px', '18.1818px'],
      lineHeight: ['20px', '24px'],

      '::before': {
        marginTop: ['-0.5126em', '-0.3463em'],
      },
      '::after': {
        marginBottom: ['-0.4856em', '-0.3193em'],
      },
    },

    nextProjectTeaserLabel: {
      fontFamily: 'heading',
      variant: 'text.default',
      marginBottom: [0],
      fontSize: ['25.7143px', '42.8571px'],
      lineHeight: ['30px', '54px'],
      '::before': {
        marginTop: ['-0.2353em', '-0.2812em'],
      },
      '::after': {
        marginBottom: ['-0.2353em', '-0.2812em'],
      },
    },
    subtitle: {
      variant: 'text.default',
      fontFamily: 'body',
      textTransform: 'uppercase',
      fontWeight: 'medium',
      letterSpacing: '0.1em',
      fontSize: ['12.1212px', '18.1818px'],
      lineHeight: ['20px', '24px'],

      '::before': {
        marginTop: ['-0.5126em', '-0.3463em'],
      },
      '::after': {
        marginBottom: ['-0.4856em', '-0.3193em'],
      },
    },
    quote: {
      variant: 'text.h1',
      textAlign: 'center',
    },
  },
};
